import { IO360DataSource } from "./IO360DataSource";
import { IO360DataSourceClient } from "./IO360DataSourceClient";
import { get, set, del, keys, getAll } from "@/Lib/IndexDB/idb";

import {
  getCounts,
  getAllCounts,
  executeQuery,
  executeJUMetadata
} from "@/Lib/Database/queryManager";

import { Promise as bbPromise } from "bluebird";

export class O360CosmosDataSource implements IO360DataSource {
  async metaDataExists(customerId: string): Promise<boolean> {
    const metaData = await executeJUMetadata(customerId);
    if (metaData == "no metadata found") return false;
    return true;
  }
  registry: IO360DataSourceClient[] = [];
  payload: any;
  projectId?: number;
  registerClient(client: IO360DataSourceClient): void {
    this.registry.push(client);
  }

  async initData(
    sendQueryiesConcur?: boolean,
    updateOnEachChunk?: boolean
  ): Promise<any[]> {
    const cacheKey = `${this.payload.queryName}_${this.payload.CustomerId}_${this.projectId}`;
    if (this.payload.Cacheable) {
      if (await this.checkForStaleCache(this.payload.CustomerId)) {
        // get all the keys for the customer and remove them
        const allKeys = await keys();
        console.log("removing keys for customer keys");
        //console.log(allKeys);
        const localCustomerId = this.payload.CustomerId;
        console.log("local customerId:" + localCustomerId);
        allKeys.forEach(key => {
          const keyStr = key.toString();
          console.log(keyStr);
          if (
            keyStr.includes(localCustomerId) &&
            !keyStr.includes("MetaDataTimeStamp")
          ) {
            console.log("removing key for:" + keyStr);
            del(keyStr);
          }
        });
      } else {
        const values: any[] = await get(cacheKey);

        // console.log("cache values found");
        // console.log(values);
        if (values) {
          if (this.registry.length > 0) {
            this.registry.forEach(client => client?.addData(values));
            return [];
          } else return values;
        }
      }
    }

    const finalResult: any[] = sendQueryiesConcur
      ? await this.runQueriesConcurrently(updateOnEachChunk)
      : await this.runQuery();
    if (this.payload.Cacheable && finalResult.length > 0) {
      try {
        set(cacheKey, finalResult);
      } catch (e) {
        console.log(`could not save ${cacheKey}`);
      }
    }
    console.log("cosmos manager init data");
    console.log(finalResult);
    return finalResult;
  }

  async runQuery(): Promise<any[]> {
    console.log("in runQuery");
    console.log(this.payload);
    //const counts: any = await getAllCounts(this.payload);
    //console.log("All counts:" + counts);

    const threasholdCount: any = await getCounts(this.payload);
    console.log("threashold count");
    console.log(threasholdCount);
    console.log("threashold" + this.payload.threashold);

    // if there is a threashold check the counts, return result
    if (this.payload.Threashold) {
      const threasholdQueryResult =
        threasholdCount.AllNumber > this.payload.Threashold ? true : false;
      const threasholdResult: any = [
        {
          threasholdExceeded: threasholdQueryResult,
          structureCount: threasholdCount.AllNumber
        }
      ];

      return threasholdResult;
    }

    // handle the case where max is less than chunk size
    if (threasholdCount.MinNumber == -1) threasholdCount.MinNumber = 1;
    if (threasholdCount.MaxNumber == -1)
      threasholdCount.MaxNumber = threasholdCount.AllNumber;
    const interval = 20000;

    const iter = Math.ceil(threasholdCount.MaxNumber / interval);
    console.log("this is the iter " + iter);

    // await this.getMetaDataTSForCustomer(this.payload.CustomerId, cacheKey);

    let finalResult: any[] = [];
    for (let i = 0; i <= iter; i++) {
      const startVal = i * interval + 1;
      if (startVal > threasholdCount.MaxNumber) break;

      if (startVal < threasholdCount.MinNumber - interval * 2) continue;

      const endVal = interval + i * interval;

      // if the all numbber count is < 20k, then do not run the between query
      if (threasholdCount.AllNumber > interval) {
        this.payload.SequenceIdStart = startVal;
        this.payload.SequenceIdEnd = endVal;
      }

      console.log("line 90 :" + this.payload);
      const chunkResult = await executeQuery(this.payload);

      if (i == 0) finalResult = chunkResult.data.Results;
      else finalResult = finalResult.concat(chunkResult.data.Results);

      // this.registry.forEach(client => await client.addData(finalResult));
      for (let x = 0; x < this.registry.length; x++)
        await this.registry[x].addData(finalResult);
    }

    if (finalResult.length === 0) {
      for (let x = 0; x < this.registry.length; x++)
        await this.registry[x].addData(finalResult);
    }
    return finalResult;
  }

  // This is a modified version of runQuery where chuncks requests are sent concurrently
  async runQueriesConcurrently(updateOnEachChunk?: boolean): Promise<any[]> {
    const threasholdCount: any = await getCounts(this.payload);
    // if there is a threashold check the counts, return result
    if (this.payload.Threashold) {
      const threasholdQueryResult =
        threasholdCount.AllNumber > this.payload.Threashold ? true : false;
      const threasholdResult: any = [
        {
          threasholdExceeded: threasholdQueryResult,
          structureCount: threasholdCount.AllNumber
        }
      ];

      return threasholdResult;
    }

    // handle the case where max is less than chunk size
    if (threasholdCount.MinNumber == -1) threasholdCount.MinNumber = 1;
    if (threasholdCount.MaxNumber == -1)
      threasholdCount.MaxNumber = threasholdCount.AllNumber;
    const interval = 20000;

    const iter = Math.ceil(threasholdCount.MaxNumber / interval);

    // await this.getMetaDataTSForCustomer(this.payload.CustomerId, cacheKey);

    let finalResult: any[] = [];
    const promises = [];
    for (let i = 0; i <= iter; i++) {
      const payload = JSON.parse(JSON.stringify(this.payload));
      const startVal = i * interval + 1;
      if (startVal > threasholdCount.MaxNumber) break;

      if (startVal < threasholdCount.MinNumber - interval * 2) continue;

      const endVal = interval + i * interval;

      // if the all numbber count is < 20k, then do not run the between query
      if (threasholdCount.AllNumber > interval) {
        payload.SequenceIdStart = startVal;
        payload.SequenceIdEnd = endVal;
      }

      // const promise = new Promise((resolve, reject) => {
      //   executeQuery(this.payload)
      //     .then((result: any) => {
      //       resolve(result);
      //     })
      //     .catch(e => {
      //       reject(e);
      //     });
      // });
      // promises.push(promise);
      promises.push(executeQuery(payload));
      // const chunkResult = await executeQuery(this.payload);
    }

    if (updateOnEachChunk) {
      const classContext = this;
      await bbPromise.map(promises, async function (result: any) {
        finalResult = finalResult.concat(result.data.Results);
        for (let x = 0; x < classContext.registry.length; x++)
          await classContext.registry[x].addData(finalResult);
      });
    } else {
      const results = await Promise.all(promises);
      finalResult = results.reduce((acc: any[], result: any) => {
        return acc.concat(result.data.Results);
      }, []);
      for (let x = 0; x < this.registry.length; x++) {
        await this.registry[x].addData(finalResult);
      }
      console.log("finalResults", finalResult);
    }

    // handle the case where the result is empty
    if (finalResult.length === 0) {
      for (let x = 0; x < this.registry.length; x++)
        await this.registry[x].addData(finalResult);
    }
    return finalResult;
  }

  // async getMetaDataTSForCustomer(customerId: string, cacheKey: string) {
  //   const JuMetadata = [];
  //   const queryResults = await executeJUMetadata(customerId);
  //   JuMetadata.push(queryResults);

  //   console.log("metaDataResults: " + JuMetadata[0]);
  //   const metaDataTimeStampKey = `MetaDataTimeStamp_${customerId}`;
  //   const metaDataTimeStamp = await get(metaDataTimeStampKey);
  //   if (metaDataTimeStamp) {
  //     if (metaDataTimeStamp < JuMetadata[0]) {
  //       set(metaDataTimeStampKey, JuMetadata[0]);
  //       del(cacheKey);
  //     }
  //   } else set(metaDataTimeStampKey, JuMetadata[0]);
  // }

  /**
   * Checks if the cache is stale based on metadata timestamp and query version.
   * @param {string} customerId - The ID of the customer.
   * @returns {Promise<boolean>} - Returns true if the cache is stale, otherwise false.
   */
  async checkForStaleCache(customerId: string): Promise<boolean> {
    // Checks if the query version is stale
    const isQueryVersionStale = await this.checkForStaleQueryVersion();

    const currentMetaDataTimeStamp = await executeJUMetadata(customerId);

    console.log("metaDataResults: " + currentMetaDataTimeStamp);
    const metaDataTimeStampKey = `MetaDataTimeStamp_${customerId}`;
    const oldMetaDataTimeStamp = await get(metaDataTimeStampKey);

    let isMetaDataStale = false;

    // Checks if the stored metadata timestamp exists and is stale
    if (oldMetaDataTimeStamp) {
      if (oldMetaDataTimeStamp < currentMetaDataTimeStamp) {
        set(metaDataTimeStampKey, currentMetaDataTimeStamp);
        console.log("cache is stale");
        isMetaDataStale = true;
      }
    } else {
      console.log("cache does not exist yet");
      set(metaDataTimeStampKey, currentMetaDataTimeStamp);
      isMetaDataStale = false;
    }

    // Determines if the cache is stale based on both query version and metadata timestamp.
    if (isQueryVersionStale && isMetaDataStale) {
      console.log("Cache is stale due to both query version and metadata timestamp.");
      return true;
    } else if (isQueryVersionStale) {
      console.log("Cache is stale due to query version mismatch.");
      return true;
    } else if (isMetaDataStale) {
      console.log("Cache is stale due to metadata timestamp.");
      return true;
    }
    return false;
  }

  /**
   * Checks if the stored query version matches the environment's query version.
   * Updates the query version if it is stale.
   * @returns {Promise<boolean>} - Returns true if the query version is stale, otherwise false.
   */
  async checkForStaleQueryVersion(): Promise<boolean> {
    // Retrieves the expected query version from environment variables.
    const envQueryVersion = parseInt(process.env.VUE_APP_OSMOVIEW_QUERIES_VERSION || '1', 10);

    const queriesVersionKey = `QueriesVersion`;

    // Retrieves the currently stored query version from the IndexDB.
    const storedQueriesVersion = await get(queriesVersionKey);

    if (storedQueriesVersion) {
      // Checks if the stored query version is different from the expected version.
      if (storedQueriesVersion !== envQueryVersion) {
        console.log("Query version is stale.");
        set(queriesVersionKey, envQueryVersion);
        return true;
      }
    } else {
      console.log("Query version does not exist.");
      set(queriesVersionKey, envQueryVersion);
      return true;
    }
    console.log("Query version is not stale.");
    return false;
  }
}
